@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
  margin: 0;
  padding: 0;
  background-image: url('./pictures/background.jpg');
  background-size: cover;
  background-position: center;
  height: 100%;
}