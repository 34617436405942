img:hover, video:hover {
    transform: scale(1.02); /* Increase size on hover */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add shadow on hover */
  }

img, video {
  max-width: 100%;
  width: auto;
  max-height: 65vh;
  height: auto;
  margin: 1.25rem auto;
  padding: 1.051%;
  background-color: #666;
}

.pointer-cursor {
  cursor: pointer;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.alert-animate-enter {
  animation: fadeIn 0.5s forwards;
}

.alert-animate-exit {
  animation: fadeOut 0.5s forwards;
}

/* Add these styles for keyboard selection feedback */
.keyboard-selected {
  border: 3px solid #007bff !important;
  box-shadow: 0 0 15px rgba(0, 123, 255, 0.7) !important;
  animation: pulse 0.5s;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.03); }
  100% { transform: scale(1); }
}

/* Keyboard instructions styling */
.instructions {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 20px;
  font-size: 0.9rem;
  text-align: center;
}

.key {
  display: inline-block;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 2px 8px;
  margin: 0 3px;
  font-family: monospace;
  box-shadow: 0 2px 0 #dee2e6;
}
